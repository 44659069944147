<header>
    <div class="header-icon">
        <img class="menu desktop-hidden" name="menu" (click)="toggleMenu()"
            src="../../../assets/img/icons/burgermenu.png" alt="">
    </div>
    <nav [ngClass]="[menuStyle]">
        <div class="nav-container">
            <div class="language-container">
                <a class="language-link" (click)="switchLanguage('de'); clickLink();">DE</a>
                <div class="vertical-lign"></div>
                <a class="language-link" (click)="switchLanguage('en'); clickLink();">EN</a>
            </div>
            <div class="nav-child">
                <a class="nav-link" href="#aboutme" (click)="clickLink()" translate>About me</a>
                <div class="underline"></div>
            </div>
            <div class="nav-child">
                <a class="nav-link" href="#skills" (click)="clickLink()" translate>Skills</a>
                <div class="underline"></div>
            </div>
            <div class="nav-child">
                <a class="nav-link" href="#portfolio" (click)="clickLink()" translate>Portfolio</a>
                <div class="underline"></div>
            </div>
            <div class="nav-child">
                <a class="nav-link" href="#contact" (click)="clickLink()" translate>Contact</a>
                <div class="underline"></div>
            </div>
        </div>
    </nav>
</header>