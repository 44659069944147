<section id="aboutme">

    <div class="about-me-content-container" data-aos="fade-right" data-aos-duration="1000">
        <h2 translate>About me</h2>
        <div class="about-me-content">
            <div class="content-child">
                <p class="content-text aligned-left" translate>About me main description</p>
            </div>
            <div class="content-child">
                <div class="icon-container">
                    <img class="icon" src="../../../assets/img/icons/lightbulb.png" alt="">
                </div>
                <p class="content-text left aligned-left" translate>About me personal things 1</p>
            </div>
            <div class="content-child">
                <div class="icon-container">
                    <img class="icon" src="../../../assets/img/icons/puzzle.png" alt="">
                </div>
                <p class="content-text aligned-left" translate>About me personal things 2</p>
            </div>
        </div>
    </div>

    <div class="about-me-image-container mobile-hidden" data-aos="fade-left" data-aos-duration="1000">
        <img class="portrait" src="../../../assets/img/portrait2.png" alt="">
        <div class="horicontal-lign"></div>
    </div>

    <img class="shadow" src="../../../assets/img/shadow2.png" alt="">


</section>