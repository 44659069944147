<header>
    <a class="logo-link" href="/">
        <img class="logo-firstname" src="../../../assets/img/firstname.png" alt="">
    </a>
    <nav>
        <div class="language-container mobile-hidden">
            <a (click)="switchLanguage('de');" class="language-link">DE</a>
            <div class="vertical-lign"></div>
            <a (click)="switchLanguage('en');" class="language-link">EN</a>
        </div>
        <div class="nav-container">
            <div class="nav-child mobile-hidden">
                <a class="nav-link" href="#aboutme" translate>About me</a>
                <div class="underline"></div>
            </div>
            <div class="nav-child mobile-hidden">
                <a class="nav-link" href="#skills" translate>Skills</a>
                <div class="underline"></div>
            </div>
            <div class="nav-child mobile-hidden">
                <a class="nav-link" href="#portfolio" translate>Portfolio</a>
                <div class="underline"></div>
            </div>
        </div>
    </nav>
</header>