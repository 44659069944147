<section id="contact">

    <div class="contact-headline-container" data-aos="fade-left" data-aos-duration="1000">
        <div class="horicontal-lign"></div>
        <h2 translate>Contact</h2>
    </div>

    <div class="contact-content-container">
        <div class="contact-content-text" data-aos="fade-right" data-aos-duration="1000">
            <h3 translate>Got a problem to solve?</h3>
            <p class="content-text aligned-left" translate>Contact me through this form, I am interested in hearing you,
                knowing
                your ideas and contributing to your projects with my work.
            </p>
            <p class="content-text aligned-left" translate>Need a Frontend developer? <b translate>Contact me!</b>
            </p>
        </div>
        <form (submit)="sendMail()" #form="ngForm" data-aos="fade-left" data-aos-duration="1000">
            <div class="input-container">
                <input type="text" ngModel name="name" #formName="ngModel" required minlength="5"
                    [attr.placeholder]="'Your Name' | translate" [class.default-border]="areAllFieldsEmpty()">
                <div *ngIf="formName.invalid && formName.touched" class="error-message" translate>Name is required and
                    must be at
                    least 5 characters.</div>
            </div>
            <div class="input-container">
                <input type="email" ngModel name="email" #formEmail="ngModel" required
                    [attr.placeholder]="'Your Email' | translate" [class.default-border]="areAllFieldsEmpty()"
                    [class.ng-invalid]="formEmail.invalid || (formEmail.value && !isValidEmail(formEmail.value))">
                <div *ngIf="(formEmail.invalid || (formEmail.value && !isValidEmail(formEmail.value))) && formEmail.touched"
                    class="error-message" translate>
                    A valid email is required.
                </div>
            </div>
            <div class="input-container">
                <!-- <textarea ngModel name="message" #formMessage="ngModel" required minlength="10"
                    placeholder="Your message"></textarea> -->
                <textarea ngModel name="message" #formMessage="ngModel" required minlength="10"
                    [attr.placeholder]="'Your message' | translate"
                    [class.default-border]="areAllFieldsEmpty()"></textarea>
                <div *ngIf="formMessage.invalid && formMessage.touched" class="error-message" translate>Message is
                    required and
                    must
                    be at least 10 characters.</div>
            </div>
            <div class="input-container">
                <div class="form-privacy-check">
                    <div class="privacy-row">
                        <input type="checkbox" ngModel name="privacy" #formPrivacy="ngModel" required>
                        <p translate>I've read the <a href="/datenschutz" target="_blank" translate>privacy policy</a>
                            and agree to the processing
                            of my data
                            as
                            outlined.
                        </p>
                    </div>
                    <div *ngIf="formPrivacy.invalid && formPrivacy.touched" class="error-message" translate>You must
                        agree to the
                        privacy policy.</div>
                </div>
            </div>
            <div class="pin-center">
                <button class="btn btn-primary" [disabled]="!form.valid" translate>Send message</button>
                <div *ngIf="!form.valid" class="overlay" (click)="checkFormValidity()"></div>
                <div *ngIf="formSubmittedSuccessfully" class="success-message" translate>Your message has been sent
                    successfully!</div>
            </div>
        </form>

    </div>

    <img class="shadow shadow1" src="../../../assets/img/shadow5.png" alt="">
    <img class="shadow shadow2" src="../../../assets/img/shadow6.png" alt="">

    <a href="#" class="arrow-up">
        <img src="../../../assets/img/icons/arrow-up.png" alt="">
    </a>

</section>