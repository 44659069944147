<section>
    <div>
        <img class="shadow" src="../../../assets/img/shadow1.png">
        <img class="portrait" src="../../../assets/img/portrait1.png" data-aos="fade-right" data-aos-duration="1000">
    </div>
    <div class="text-container">
        <div class="headline">
            <span data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100" translate [class.de-iAm]="currentLanguage === 'de'">I am</span>
            <div class="main-headline" data-aos="fade-left" data-aos-duration="1000">
                <h1>Tobias Termer</h1>
                <h3 translate>Frontend Developer</h3>
            </div>
        </div>
        <div class="btn-container" data-aos="fade-down-left" data-aos-duration="1000">
            <a  class="btn btn-primary" href="#contact" translate>Contact me</a>
        </div>
    </div>
    <img class="bow" src="../../../assets/img/bow.png" alt="">
    <div class="social-container">
        <div class="horicontal-lign"></div>
        <div class="social-icons">
            <a href="https://github.com/tobiastermer" target="_blank">
                <img class="icon" src="../../../assets/img/icons/github.png" alt="">
            </a>
            <a href="https://www.linkedin.com/in/tobias-termer-778328143/" target="_blank">
                <img class="icon" src="../../../assets/img/icons/linkedin.png" alt="">
            </a>
            <a href="mailto:mail@tobias-termer.de" target="_blank">
                <img class="icon" src="../../../assets/img/icons/email.png" alt="">
            </a>
            <a href="mailto:mail@tobias-termer.de" class="mobile-hidden" target="_blank">
                mail(at)tobias-termer.de
            </a>
        </div>
    </div>
    <div class="scroll-down-container mobile-hidden">
        <p class="scroll-down-text" translate>Scroll down</p>
        <img class="scroll-down-icon" src="../../../assets/img/icons/arrow-right-line.png" alt="">
    </div>

</section>