<section id="skills">


    <div class="skills-headline-container" data-aos="fade-left" data-aos-duration="1000">
        <div class="skills-headline-content">
            <h2 translate>My skills</h2>
            <p class="content-text aligned-right" translate>I have grown my frontend developments skills that have allowed me to
                create different real projects.</p>
            <a class="btn btn-primary mobile-hidden" href="#contact" translate>Get in touch</a>
        </div>
        <div class="horicontal-lign"></div>
    </div>

    <div class="skills-content-container" data-aos="fade-right" data-aos-duration="1000">
        <div class="skills-icons-container">
            <app-skill *ngFor="let skill of skills;" [skill]="skill"></app-skill>
        </div>
    
        <a href="#contact" class="btn btn-primary desktop-hidden" translate>Get in touch</a>
    </div>
    
    <img class="shadow" src="../../../assets/img/shadow3.png" alt="">


</section>