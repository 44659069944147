<section id="portfolio">

    <div class="portfolio-headline-container" data-aos="fade-left" data-aos-duration="1000">
        <div class="horicontal-lign w-short"></div>
        <div class="portfolio-headline-content">
            <h2 translate>Portfolio</h2>
            <p class="content-text aligned-center mobile-hidden" data-aos="fade-right" data-aos-duration="1000" translate>This page is a sample of my work - please feel free to try them out.
            </p>
        </div>
        <div class="horicontal-lign w-long"></div>
    </div>

    <div class="portfolio-projects-container">

        <p class="content-text aligned-center desktop-hidden" data-aos="fade-right" data-aos-duration="1000" translate>This page is a sample of my work - please feel free to try them out.
        </p>

        <app-project *ngFor="let project of portfolio; let i = index" [projectname]="project.projectname | translate"
            [languages]="project.languages" [description]="project.description | translate" [linkLive]="project.linkLive"
            [linkGit]="project.linkGit" [picture]="project.picture" [index]="i">
        </app-project>

        <img class="shadow" src="../../../assets/img/shadow4.png" alt="">

    </div>

</section>