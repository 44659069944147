<section>
    <h1 translate>Impressum</h1>
    <div>
        <h2 translate>Angaben gemäß § 5 TMG</h2>
        <p>Tobias Termer<br />
            Warenerstr. 1<br />
            31832 Springe</p>
    </div>
    <div>
        <h2 translate>Kontakt</h2>
        <p translate>E-Mail: mail(at)tobias-termer.de</p>
    </div>
    <div>
        <h2 translate>Umsatzsteuer-ID</h2>
        <p translate>Umsatzsteuer-Identifikationsnummer gemäß $ 27 a Umsatzsteuergesetz:<br />
            DE363783808</p>
    </div>
    <div>
        <h2 translate>EU-Streitschlichtung</h2>
        <p translate>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener
        noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im
            Impressum.</p>
    </div>
    <div>
        <h2 translate>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h2>
        <p translate>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>
    </div>
    <p translate>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>

</section>