<footer>
    <div class="footer-row">
        <img class="logo-firstname" src="../../../assets/img/firstname.png" alt="">
        <p>© Tobias Termer 2024</p>
        <div class="social-container">
            <div class="social-icons">
                <a href="https://github.com/tobiastermer" target="_blank">
                    <img class="icon" src="../../../assets/img/icons/github.png" alt="">
                </a>
                <a href="https://www.linkedin.com/in/tobias-termer-778328143/" target="_blank">
                    <img class="icon" src="../../../assets/img/icons/linkedin.png" alt="">
                </a>
                <a href="mailto:mail@tobias-termer.de" target="_blank">
                    <img class="icon" src="../../../assets/img/icons/email.png" alt="">
                </a>
            </div>
        </div>
    </div>
    <div class="footer-row2">
        <a href="/impressum" translate>Impressum</a>
        <p>|</p>
        <a href="/datenschutz" translate>Datenschutz</a>    
    </div>
    
</footer>